<script>
  import { onMount } from "svelte";
  import Nav from "@components/Nav";

  onMount(() => {});
</script>

<style lang="css">
  .hero {
  }

  .titles {
    font-family: var(--font-header), var(--font-list);
    text-transform: uppercase;
    text-align: center;
  }

  .titles h1 {
    font-size: 2.275em;
    font-weight: 800;
    line-height: 1.2em;
  }
</style>

<header class="hero">
  <Nav />
  <div class="titles">
    <h1>QRCode Live</h1>
  </div>
</header>
