<script>
  import { onMount } from "svelte";

  let stuck = false;

  function onScroll(ev) {
    stuck = window.pageYOffset > 0;
  }

  $: classes = "nav" + (stuck ? " stuck" : "");

  onMount(() => {
    addEventListener("scroll", onScroll, { passive: true });

    return () => {
      removeEventListener("scroll", onScroll);
    };
  });
</script>

<style lang="css">
  .nav {
    width: 100%;
    height: 56px;
    display: flex;
    padding: 0 32px;
    position: relative;
    background: transparent;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 9;
    top: 0;
  }

  .nav::after {
    opacity: 0;
    content: "";
    transition: opacity var(--transition-duration);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background: var(--offwhite);
    will-change: opacity;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .stuck::after {
    opacity: 1;
  }

  .logo {
    height: 24px;
    z-index: 1;
  }

  .nav li {
    display: inline-block;
    position: relative;
    margin-left: 24px;
    list-style: none;
    float: right;
    z-index: 1;
  }

  .link_external {
    position: relative;
    padding-right: 8px;
  }

  .link_external::after {
    content: "";
    position: absolute;
    background: transparent no-repeat center;
    background-image: url(~@assets/link.svg);
    height: 12px;
    width: 12px;
    right: -8px;
  }

  .links li {
    float: none;
    margin-left: 0;
    margin-right: 24px;
  }

  @media screen and (max-width: 421px) {
    .nav {
      font-size: 75%;
      padding: 0 16px;
    }
    .nav li {
      margin-left: 16px;
    }
    .links li {
      margin-left: 0;
      margin-right: 16px;
    }
  }
</style>

<nav class={classes}>
  <ul class="links">
    <li>
      <a href="/">QR Live Reader</a>
    </li>
    <li>
      <a href="/about">About</a>
    </li>
  </ul>

</nav>
