<script>
  import Navaid from "navaid";
  import { onMount } from "svelte";
  import Footer from "@components/Footer";
  import HeroSimple from "@components/HeroSimple";

  let Route,
    params = {};

  function draw(m, params) {
    params = params || {};
    Route = m.default || m;
  }

  function track(obj) {
    if (window.ga) {
      ga.send("pageview", { dp: obj.uri });
    }
  }

  const router = Navaid("/")
    .on("/", () => import("@pages/QRReader").then(draw))
    .on("/about", () => import("@pages/About").then(draw));

  onMount(() => {
    router.listen();
    addEventListener("replacestate", track);
    addEventListener("pushstate", track);
    addEventListener("popstate", track);

    return () => {
      removeEventListener("replacestate", track);
      removeEventListener("pushstate", track);
      removeEventListener("popstate", track);
      router.unlisten();
    };
  });
</script>

<style lang="css">
  .app {
    position: relative;
  }

  .wrapper {
    width: 75%;
    margin: 0 auto;
    position: relative;
    min-height: calc(55vh - 16px);
    z-index: 1;
  }

  @media screen and (max-width: 769px) {
    .wrapper {
      width: 90%;
    }
  }
</style>

<div class="app">
  <HeroSimple />

  <main class="wrapper">
    <svelte:component this={Route} {params} />
  </main>

  <Footer />
</div>
