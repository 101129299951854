import GAnalytics from "ganalytics";
import App from "@components/App";
import "./index.css";
// require("jsqrcode/src/grid");
// require("jsqrcode/src/version");
// require("jsqrcode/src/detector");
// require("jsqrcode/src/formatinf");
// require("jsqrcode/src/errorlevel");
// require("jsqrcode/src/bitmat");
// require("jsqrcode/src/datablock");
// require("jsqrcode/src/bmparser");
// require("jsqrcode/src/datamask");
// require("jsqrcode/src/rsdecoder");
// require("jsqrcode/src/gf256poly");
// require("jsqrcode/src/gf256");
// require("jsqrcode/src/decoder");
// require("jsqrcode/src/qrcode");
// require("jsqrcode/src/findpat");
// require("jsqrcode/src/alignpat");
// require("jsqrcode/src/databr");

window.app = new App({
  target: document.querySelector("#app"),
  hydrate: true,
});

if (process.env.NODE_ENV === "production") {
  window.ga = new GAnalytics("UA-168542810-1");

  // Service Worker registration
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js");
  }
}
